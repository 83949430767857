<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='12' :md='6' :xl='2' :style="{ marginBottom: '24px' }">
        <a-card :loading='loading'>
          <div class="card_content">
            <a-row>
              <a-col :span="24"><span class="card_title">用户数：</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="24"><span class="color-red">{{dashboard.totalUser}}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>

      <a-col :sm='12' :md='6' :xl='16' :style="{ marginBottom: '24px' }">
        <a-card :loading='loading'>
          <div class="card_content">
            <a-row>
              <a-col :span="4"><span class="card_title">可用积分：</span></a-col>
              <a-col :span="4"><span class="card_title">兑换积分：</span></a-col>
              <a-col :span="4"><span class="card_title">份额数：</span></a-col>
              <a-col :span="4"><span class="card_title">抵扣份额数：</span></a-col>
              <a-col :span="4"><span class="card_title">累计值：</span></a-col>
              <a-col :span="4"><span class="card_title">抵扣累计值：</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="4"><span class="color-red">{{ dashboard.cashBalance }}</span></a-col>
              <a-col :span="4"><span class="color-red">{{ dashboard.exchangeIntegralBalance }}</span></a-col>
              <a-col :span="4"><span class="color-red">{{ dashboard.shareCountBalance }}</span></a-col>
              <a-col :span="4"><span class="color-red">{{ dashboard.usedShareCount }}</span></a-col>
              <a-col :span="4"><span class="color-red">{{ dashboard.cumulativeValue }}</span></a-col>
              <a-col :span="4"><span class="color-red">{{ dashboard.usedCumulativeValue }}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='username'>
        <a-input v-model='form.username' style="width: 200px" placeholder="姓名" />
      </a-form-model-item>

      <a-form-model-item prop='mobile'>
        <a-input v-model='form.mobile' style="width: 200px" placeholder="手机号" />
      </a-form-model-item>

      <a-form-model-item prop='type'>
        <a-select v-model="form.memberType" placeholder="身份" style="width: 200px">
          <a-select-option :value="1">
            用户
          </a-select-option>
          <a-select-option :value="2">
            体验官
          </a-select-option>
          <a-select-option :value="3">
            备选服务
          </a-select-option>
          <a-select-option :value="4">
            备选运营
          </a-select-option>
          <a-select-option :value="5">
            服务中心
          </a-select-option>
          <a-select-option :value="6">
            服务中心(股东)
          </a-select-option>
          <a-select-option :value="7">
            运营中心
          </a-select-option>
          <a-select-option :value="8">
            运营中心(股东)
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='spreaderName'>
        <a-input v-model='form.spreaderName' style="width: 200px" placeholder="推荐人" />
      </a-form-model-item>

      <a-form-model-item prop='areaShortName'>
        <a-input v-model='form.areaShortName' style="width: 200px" placeholder="分公司简称" />
      </a-form-model-item>

      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true,
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' :columns='columns' :scroll='{ x: "100%"}' :data='loadData' >
        <span slot='username1' slot-scope='text, record'>
          <template>
              <a @click="handleDetail(record)">{{text}}</a>
          </template>
        </span>
        <span slot='fillStatus' slot-scope='text, record'>
          <template>
             <span v-if="text=='1'">满足</span>
             <span v-if="text=='2'">不满足</span>
             <span v-if="text=='3'">正常</span>
          </template>
        </span>

        <span slot='spreaderType' slot-scope='text, record'>
          <template>
              {{getTypeNameStr(text,record)}}
          </template>
        </span>
        <span slot='username' slot-scope='text, record'>
          <template>
            <span v-if="record.type=='normal'">{{record.username}} </span>
            <span v-else >{{record.masterName}}</span>

          </template>
        </span>
        <span slot='exchangeIntegralPermission' slot-scope='text, record'>
          <template>
             <a-badge v-if='text=="0"' dot status='default' :text='"已闭"' />
             <a-badge v-if='text=="1"' dot status='success' :text='"已启"' />
          </template>
        </span>
        <span slot='action' slot-scope='text, record'>
          <template>
<!--            <span>-->
<!--                  <a-divider type='vertical' />-->
<!--                  <a @click='handleDetail(record)'>详情</a>-->
<!--            </span>-->
            <span>
                  <a-divider type='vertical' />
                  <a v-if="record.pause==0" @click='handlePause(record,"1")'>停用</a>
                  <a v-if="record.pause==1" @click='handlePause(record,"0")'>启用</a>
            </span>
            <span>
                  <a-divider type='vertical' />
              <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消'
                                          @confirm='handleDelete(record)'>
                <a href='#'>删除</a>
              </a-popconfirm>
            </span>
            <span>
                  <a-divider type='vertical' />
                  <a  v-if="record.exchangeIntegralPermission==0" @click='handleExchange(record,"1")'>开启转赠</a>
                  <a  v-if="record.exchangeIntegralPermission==1" @click='handleExchange(record,"0")'>关闭转赠</a>
            </span>
          </template>
        </span>
        <span slot='avatar' slot-scope='text'>
          <template>
            <a-avatar v-if='text' :src='text' />
            <a-avatar v-else icon='user' />
          </template>
        </span>
        <span slot='status' slot-scope='text'>
          <template>
            <a-badge v-if='text==0' dot status='default' :text='"注销"' />
            <a-badge v-if='text==1' dot status='processing' :text='"有效"' />
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { queryOperators, removeOperator, memberHeardData, putPause,putExchange} from '@/api/operator'
import moment from "moment";
import TagSelectOption from "@/components/TagSelect/TagSelectOption";

const columns = [
  // {
  //   width: 100,
  //   title: '昵称1',
  //   dataIndex: 'nickname',
  //   scopedSlots: { customRender: 'nickname' }
  // },
  {
    width: 100,
    title: '姓名',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username1' }
  },
  {
    width: 120,
    title: '手机号',
    dataIndex: 'mobile',
    scopedSlots: { customRender: 'mobile' }
  },
  {
    width: 70,
    title: '份额数',
    dataIndex: 'totalShareValue',
    scopedSlots: { customRender: 'totalShareValue' },
    sorter: true,
  },
  {
    width: 70,
    title: '分值类型',
    dataIndex: 'valueName',
    scopedSlots: { customRender: 'valueName' }
  },
  {
    width: 70,
    title: '累计值',
    dataIndex: 'cumulativeValue',
    scopedSlots: { customRender: 'cumulativeValue' },
    sorter: true,
  },
  {
    width: 70,
    title: '可用积分',
    dataIndex: 'cashBalance',
    scopedSlots: { customRender: 'cashBalance' },
    sorter: true,
  },
  {
    width: 70,
    title: '兑换积分',
    dataIndex: 'exchangeIntegralBalance',
    scopedSlots: { customRender: 'exchangeIntegralBalance' },
    sorter: true,
  },
  {
    width: 70,
    title: '转赠',
    dataIndex: 'exchangeIntegralPermission',
    scopedSlots: { customRender: 'exchangeIntegralPermission' }
  },
  {
    width: 80,
    title: '推荐人',
    dataIndex: 'spreaderName',
    scopedSlots: { customRender: 'spreaderName' }
  },
  {
    width: 100,
    title: '推荐人身份',
    dataIndex: 'spreaderTypeName',
    scopedSlots: { customRender: 'spreaderTypeName' }
  },
  {
    width: 100,
    title: '分公司简称',
    dataIndex: 'areaShortName',
    scopedSlots: { customRender: 'areaShortName' }
  },

  {
    width: 100,
    title: '注册时间',
    dataIndex: 'createTime'
  },
  {
    width: 150,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';

export default {
  name: 'OperatorList',
  components: {
    TagSelectOption,
    STable, Ellipsis
  },
  data() {
    return {
      loading: true,
      dashboard: {}, //表头用户数统计
      formatType,
      dateArr: [baseStart, baseEnd],
      form: {
        name: "",
        start: baseStart,
        end: baseEnd,
      },
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let _form = this.getMemberType(this.form.memberType);
        let param = Object.assign(parameter, this.queryParam,this.form,_form);
        let sortField = param.sortField;
        if(sortField == 'cashBalance'){
          param.sortField = 'cash_balance';
        } else if(sortField == 'exchangeIntegralBalance'){
          param.sortField = ' exchange_integral_balance';
        }

        return queryOperators(param)
          .then(datum => {

            return datum
          })
      }
    }
  },
  created() {

      this.getHeaderData();
  },
  methods: {
    getTypeNameStr(type,record) {
      if(record.shareholder=='1'){
        //股东判断
        return '股东'
      }
      if(type=='store'){
        return '门店'
      }else if(type=='center') {
        return '服务中心';
      }else if(type=='president') {
        return '运营总裁';
      }else if(type=='normal'){
        // debugger;
        if(record.level=='center'){
          return '备选服务';
        }
        return '用户';
      }else{
        return '';
      }
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
      // debugger;
      this.loading=true;
      this.getHeaderData();
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = moment(baseStart).startOf('day').format(this.formatType + " HH:mm");
      this.form.end = moment(baseEnd).endOf('day').format(this.formatType + " HH:mm");
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleDelete(record) {
      removeOperator({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleDetail(record) {
      this.$router.push('/permission/operator/detail?id='+record.id)
    },
    getHeaderData(){
      // let _form = this.getMemberType(this.form.memberType,this.form);
      let param = Object.assign(this.form);
      memberHeardData(param).then(result =>{
        this.dashboard = result;
        this.loading = false;
      });
    },
    handlePause(record,pause){
      putPause(Object.assign({id:record.id,pause:pause})).then(result=>{
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleExchange(record,exchange){
      putExchange(Object.assign({id:record.id,exchangeIntegralPermission:exchange})).then(result=>{
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    getMemberType(memberType){
      let _form = [];
      if(memberType=='1'){//用户
        _form.type = 'normal';
        _form.levelIn = ['normal','vip'];
        _form.shareholder = '0';
      } else if(memberType=='2'){//体验官
        _form.type = 'normal';
        _form.levelIn = ['exp'];
        _form.shareholder = '0';
      } else if(memberType=='3'){//备选服务
        _form.type = 'normal';
        _form.levelIn = ['center'];
        _form.shareholder = '0';
      } else if(memberType=='4'){//备选运营
        _form.type = 'normal';
        _form.levelIn = ['president'];
        _form.shareholder = '0';
      } else if(memberType=='5'){//服务中心
        _form.type = 'center';
        _form.shareholder = '0';
      } else if(memberType=='6'){//服务中心（股东）
        _form.type = 'center';
        _form.shareholder = '1';
      } else if(memberType=='7'){//运营中心
        _form.type = 'president';
        _form.shareholder = '0';
      } else if(memberType=='8'){//运营中心（股东）
        _form.type = 'president';
        _form.shareholder = '1';
      }
      return _form;
    }
  }
}
</script>
